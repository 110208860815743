// Palette
$color-black: #00112c;
$color-gray-darker: #687282;
$color-gray-dark: #b9c4c9;
$color-gray: #d4d9db;
$color-gray-light: #e6e9eb;
$color-gray-lighter: #f7f8f9;
$color-white: #fff;

$color-blue: #06f;
$color-blue-light: #99c2ff;
$color-blue-lighter: #e5efff;
$color-green: #0abf53;
$color-orange: #ffae42;
$color-orange-light: #ffeacc;
$color-orange-dark: #7f4a00;
$color-red: #d10244;

// Application
$color-primary: $color-blue;
$color-success: $color-green;
$color-warning: $color-orange;
$color-alert: $color-red;
$color-disabled: $color-gray-light;

// Border colors
$color-border-dark: $color-gray-dark;
$color-border: $color-gray;
$color-border-light: $color-gray-light;
